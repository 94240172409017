// Wishlist
.wishlist-title {
	margin-bottom: 2.8rem;
	
	h2 {
		padding: 5px;
		padding-top: 1rem;
	}
}

.wishlist-table-container {
    margin-bottom: 5.5rem;

    .table-title {
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 1.3em;
        font-weight: 400;
        letter-spacing: -0.7px;
        line-height: 1.42857;
        text-transform: uppercase;
    }

	.btn {
		height: 42px;
		width: auto;
		padding: 0 25px !important;
		font-size: 13px;
		line-height: 42px;
		text-indent: 0;
	}
	
	.btn-quickview {
		margin-right: 1rem;
		background: #f4f4f4;
		color: #222529;
		font-family: $font-family;

		&:hover {
			background-color: $primary-color;
			color: #fff;
		}
	}

	.btn-shop {
		font-weight: 600;
		text-transform: uppercase;
		min-width: 160px;
	}
}

.table.table-wishlist {
	tr {
		th {
            padding: 10px 5px 10px 16px;
            
			&.thumbnail-col {
				width: 10%;
			}

			&.product-col {
				width: 29%;
			}

			&.price-col {
				width: 13%;
            }
            
            &.status-col {
                width: 19%;
            }
        }
        
        td {
            padding: 20px 5px 23px 16px;
        }
    }
    
    .product-price {
        color: inherit;
        font-size: 1.4rem;
        font-weight: 400;
    }

    .price-box {
        margin-bottom: 0;
    }

    .stock-status {
        color: $headings-text;
        font-weight: 600;
    }
}

.box-content {
    .table-wishlist,
    .table-cart {
        margin-bottom: 15px;
        font-size: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        margin-bottom: 1em;

        .wishlist-empty {
            margin-bottom: 1rem;
            text-align: center;
        }
    }

    .btn-go-shop {
        padding: 16px 0;
        margin-top: 2rem;
        border: none;
        text-transform: uppercase;
        text-align: center;
        min-width: 200px;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: -.015em;
        background-color: #353a40;
        color: #fff;
        
        &:hover {
            background-color: $primary-color;
            color: #fff;
        }
    }
}

i.cart-empty,
i.wishlist-empty {
    font-size: 100px;
    color: #d3d3d4;
}

//custom for no products page in wishlist
.wishlist-empty-page {
	padding-top: 9px;
	margin-bottom: 8.3rem;

	i {
		margin-bottom: 1.7rem;
		font-size: 100px;
		color: #d3d3d4;
	}

	p {
		margin-bottom: 3.1rem;
		font-weight: 600;
		color: #777;
	}

	.btn-shop {
		padding: 1.6rem 0;
		padding-bottom: 1.5rem;
		min-width: 200px;
		font-size: 15px;
		letter-spacing: -.015em;
		text-align: center;
	}
}

//custom alert
.success-alert {
	padding-top: 48px;
	padding-left: 3px;

	p {
		margin-bottom: 1.7rem;
		font-size: 16px;
		font-weight: 500;
		color: #222529;

		&::before {
			content: "\f00c";
			position: relative;
			top: 2px;
			margin-right: 9px;
			font-size: 20px;
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			color: #0cc485;
		}
	}
}

@include mq(xl, max) {
    .wishlist-table-container {
		.btn {
			width: 100%;

			&:first-child {
				margin-bottom: 1rem;
			}
		}
	}
}

@include mq(md, max) {
	.wishlist-empty-page {
		padding-top: 7rem;
	}

    .wishlist-table-container {
		border-top: 4px solid $primary-color;
    }

	.table.table-wishlist {
		border: 1px solid #e7e7e7;
		border-top: 0;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);

		&,
		tbody{
			display: block;
		}

		thead {
			display: none;
		}

		tr {
			td {
				padding: .5rem 1rem;
				border-top: 0;

				&.product-col {
					padding-bottom: .5rem;
				}
			}
		}

		.product-row {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-direction: column;
			flex-direction: column;
			justify-content: center;
            align-items: center;
            padding: 3rem 0;
			border-top: 1px solid #ddd;

			&:first-child {
				border-top: 0;
			}
		}

		.product-col {
			-ms-flex-direction: column;
			flex-direction: column;
			-ms-flex-pack: center;
			justify-content: center;
			text-align: center;

			.product-image-container {
				-ms-flex: 0 0 auto;
				flex: 0 0 auto;
				margin-right: 0;
				margin-bottom: 1rem;
			}
        }
        
        .btn-shop {
            width: 100%;
        }
	}
}