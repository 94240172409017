// Pagination

// # Contents

// 1. Toolbox
// 1.1) Toolbox Base
// 1.2) Toolbox Item Base
// 2. Elements
// 2.1) Pagination
// 2.2) Layout Button
// 3. Responsive

// # Settings

// # Variables

$p-pagination-color: #706f6c !default;
$p-pagination-font-size: 15px !default;
$p-pagination-font-weight: 700 !default;

// 1. Toolbox

// 1.1) Toolbox Base
.toolbox {
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	justify-content: space-between;
	-ms-flex-pack: justify;
	margin-bottom: 1rem;
	font-size: 1.2rem;
	line-height: 1.5;

	.select-custom {
		&:after {
			right: 1.5rem;
			font-size: 1.6rem;
			color: $primary-color-dark;
		}

		.form-control {
			max-width: 160px;
			padding-right: 2.5rem;
		    padding-left: .8rem;
			font-size: 1.3rem;
			padding-top: 1px;
		}
	}

	label {
		margin: 1px 1.1rem 0 0;
		color: #777;
		font-size: 1.3rem;
		font-weight: 400;
		font-family: $font-family;
	}

	.form-control {
		display: inline-block;
		margin-bottom: 0;
		padding: 0 .8rem;
		color: $body-text;

		&:focus {
			color: $body-text;
		}
	}

	select.form-control:not([size]):not([multiple]) {
		height: 34px;
	}

	.toolbox-show .select-custom:after {
		right: 1rem;
	}
}

.toolbox,
.toolbox-left,
.toolbox-right,
.toolbox-item {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

// 1.2) Toolbox Item Base
.toolbox-item {
	margin-bottom: 10px;

	&:not(:last-child) {
		margin-right: 10px;
	}

	&.layout-modes {
		margin-top: -1px;
	}

	&.toolbox-sort {
		margin-right: 1.5rem;
	}

	.select-custom {
		margin-bottom: 0;
	}
}

// 2. Elements

// 2.1) Pagination
.toolbox-pagination {
	border-top: 1px solid #efefef;
    padding-top: 2.5rem;
	margin-bottom: 3.5rem;
}

.pagination {
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	color: $p-pagination-color;
	font-size: $p-pagination-font-size;
	font-weight: $p-pagination-font-weight;
	font-family: $font-family;

}
.page-item {
	&:first-child .page-link, &:last-child .page-link {
		border-radius: 0;
	}

	&:not(:first-child) {
		margin-left: .5rem;
	}

	&.active .page-link {
		color: $p-pagination-color;
		background-color: transparent;
		border-color: $primary-color;
	}

	&.disabled {
		display: none;
	}
}

.page-link {
	border: 1px solid #ccc;
	padding: 0 .5em;
	min-width: 2.2em;
	color: inherit;
	line-height: 2.1em;
	text-align: center;

	&:hover,
	&:focus {
		color: $p-pagination-color;
		background-color: transparent;
		border-color: $primary-color;
		box-shadow: none;
	}

	i {
		font-size: 20px;
	}
}

.page-link-btn {
	padding: 0;

	i {
		position: relative;
		top: 1px;
		line-height: 1;
	}
}

// 2.2) Layout Button

.layout-btn {
	display: inline-block;
	width: 1.2em;
	color: #666;
	font-size: 16px;
	line-height: 34px;
	text-align: center;

	&:not(:last-child) {
		margin-right: 4px;
	}

	&.active {
		color: $primary-color;
	}
}

// 3. Responsive

@include mq(lg) {
	.toolbox-pagination {
		margin-bottom: 0;
	}
}

@include mq(lg, max) {
	aside .toolbox-item {
		display: block;

		&:after {
			content: normal;
		}
	}

	.toolbox:not(.toolbox-pagination) {
		padding: 10px;
		background-color: #f4f4f4;
		margin-bottom: 2rem;

		.toolbox-item {
			margin-bottom: 0;
		}
	}

	.toolbox {
		label {
			font-size: 11px;
			font-weight: 600;
			color: $primary-color-dark;
		}

		.select-custom .form-control {
			font-size: 11px;
			font-weight: 600;
			max-width: 140px;
			text-transform: uppercase;
			color: $primary-color-dark;
		}
	}
}

@include mq(md, max) {
	.toolbox {
		label {
			display: none;
		}

		.select-custom:after {
			padding: 2px 0 0;
		}
	}
}

@include mq(sm, max) {
	.toolbox {
		.layout-modes {
			display: none;
		}

		.toolbox-show, .toolbox-sort {
			margin-right: 0;
		}

		.select-custom .form-control {
			max-width: 132px;
		}
	}
}