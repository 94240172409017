// Demo 3
// # Content

// 1. Header
// 	  1.1) Header Base
// 	  1.2) Header Element
// 	  	   1.2.1) Header Icons
// 	  	   1.2.2) Header Search
// 	  	   1.2.3) Header Main Nav

// 2. Footer
// 	  2.1) Footer Base
// 	  2.2) Footer Element
// 	  	   2.2.1) Footer Social Icons
// 	  	   2.2.2) Footer Contact Info
//	  	   2.2.3) Footer Payments
//	  	   2.2.4) Footer Newsletter

// 3. Component
//	  3.1) Breadcrumb
//	  3.2) Product Default
//	   	   3.2.1) Product Details
//	  	   3.2.2) Product Widget

// 4. Responsive

// 1. Header
// 1.1) Header Base
.header-middle {
	.header-right {
		margin-right: -2px;
	}
}

// 1.2) Header Element
// 1.2.1) Header Icons
.header-icon {
    margin-left: -3px;
    margin-right: 23px;
    font-size: 26px;
    padding-bottom: 2px;

	.icon-user-2 {
		font-size: 26px;
	}
}

.cart-dropdown {
	padding-bottom: 4px;

	.dropdown-toggle i {
		font-size: 26px;
		transform: translateY(-1px);
	}
}

.cart-count {
	width: 16px;
	height: 16px;
	font-size: 0.963rem;
	line-height: 15px;
}

.dropdown-arrow {
	&:after {
		font-size: 17px;
	}

	.badge-circle {
		top: 1px;
		left: 18px;
	}
}

.dropdown-arrow:after {
	margin-left: 15px;
}

// 1.2.2) Header Search
.header-search {
    margin-right: 2.9rem;
}

// 1.2.3) Header Main Nav
.main-nav {
	margin-left: 3.28vw;

	.menu>li {
		margin-right: 1px;
	
		>a {
			font-family: Poppins,sans-serif;
			font-size: 14px;
			font-weight: 700;
			line-height: 20px;
			letter-spacing: -0.25px;
			color: #141a1f;
			padding: 15px 15px 15px 15px;
		}
	}
}

.pl-15 {
	padding-left: 15px !important
}

// 2. Footer
// 2.1) Footer Base
.footer-top {
	.container {
		&:after {
			content: '';
			display: block;
			flex: 0 0 100%;
			border-top: 1px solid #e7e7e7;
			margin-top: 4rem;
		}

		&:not(.top-border),
		&.top-border:before {
			padding-top: 4rem;
		}
	}

	form {
		margin: 0;
	}

	button {
	    padding: .7em 1.244em .6em;

		&:hover {
			opacity: .8;
		}
	}

	.form-control {
	    padding: 1.25em 1em 1.25em;
		width: 100%;
		height: auto;
		margin: 0;
		border: none;
		color: $body-text;
		background-color: #f4f4f4;
		font-family: $font-family;
		font-size: 1.2rem;

		&::placeholder {
			color: #999;
		}

		&:focus,
		&:active {
			background-color: #f4f4f4;
		}
	}
}

.footer-middle {
	.logo {
		margin-bottom: 4rem;
		margin-right: 0;
	}

	.links {
		line-height: 2.4rem;
	}
}

.footer {
	line-height: 1.4;
	letter-spacing: .005em;

	.widget-title {
	    line-height: 1.4;
		margin: 0 0 1.5rem;
		font-size: 1.5rem;
	}

// 2.2) Footer Element
// 2.2.1) Footer Social Icons
   .social-icon:not(:hover):not(:active):not(:focus) {
		color: #525252;
	}

	.social-icons {
		margin-right: 2px;
	}

	.social-icon+.social-icon {
		margin-left: 8px;
	}

// 2.2.2) Footer Contact Info
	.contact-widget {
		margin: 0 0 1rem;
	
		.widget-title {
			font-weight: 400;
			font-size: 1.3rem;
			font-family: $font-family;
			letter-spacing: .005em;
			color: $primary-color;
			margin-bottom: 0;
		}
	
		a {
			display: block;
		}
	}

// 2.2.3) Footer Payments
	.payment-icons .payment-icon {
		border-radius: 0;
	}
}

// 2.2.4) Footer Newsletter
.widget-newsletter-info {
	margin-right: 4.8rem;
}

.widget-newsletter-title {
    letter-spacing: .075em;
    font-size: 1.4rem;
    padding-bottom: 1px;
}

p.widget-newsletter-content {
    font-family: $font-family;
    line-height: 20px;
    letter-spacing: 0;
    color: #777777;
}

.widget-account {
	padding: 0 7rem;
}

// 3. Component
// 3.1) Breadcrumb
.breadcrumb {
    padding: 1.2rem 0;
    letter-spacing: .08em;
}

.breadcrumb-nav {
    margin-bottom: 3.2rem;
}

.product-page .breadcrumb-nav {
	margin-bottom: 3.5rem;
}

.breadcrumb-item {
	font-family: $font-family;

	&+.breadcrumb-item {
		padding-left: 1.1rem;
	
		&:before {
			padding-right: .9rem;
			font-size: 1.6rem;
			margin-top: -3px;
			font-weight: 400;
		}
	}
}

// 3.2) Product Default
// 3.2.1) Product Details
.product-default {
	position: relative;
	margin-bottom: 2.3rem;
	
	.product-details {
		padding: 0;
	}

	&:not(.product-widget):hover {
		box-shadow: none;

		figure {
			box-shadow: 0 25px 35px -5px rgba(0, 0, 0, 0.1);
		}

		figure .btn-quickview {
			padding-top: 1.2rem;
			padding-bottom: 1.3rem;
			transition: padding-top .2s, padding-bottom .2s;
		}		
	}
}

.ratings-container {
    margin-left: 0;
}

// 3.2.2) Product Widget
.product-widget .product-title {
	color: $body-text;

	&:hover {
		color: $primary-color;
	}
}

// 4. Responsive
@include mq(lg) {
	.header-search {
		margin-top: 2px;
	}
}

@include mq(lg, max) {
	footer .widget-newsletter {
		margin-bottom: 2rem;
	}

	footer .widget {
		margin-bottom: 3rem;
	}

	.header-middle.fixed {
		.header-search {
			margin-top: -3px;
			margin-right: 2.4rem;
		}
	}
}

@include mq(md, max) {
	.widget-newsletter-info {
		margin-bottom: 1.5rem;
	}

	.widget-newsletter .form-control {
		max-width: 70%;
	}

	.footer-top {
		.form-control,
		button {
			height: 40px;
		}
	}

	.header-icon.header-icon-wishlist {
		margin-right: 2rem;

		i {
			font-size: 2.8rem;
		}
	}
}

@include mq(sm, max) {
	.header-icon.header-icon-wishlist {
		margin-right: 1rem;
	}
}

@include only_for_ie() {
	.header-search-wrapper {
		margin-top: 9px;
	}
}

@include only_for_edge() {
	.header-search-wrapper {
		margin-top: 9px;
	}
}

// Menu Toggler
.mobile-menu-togger {
	padding: 1.2rem .5rem .8rem;
}